.title-md {
	font: 900 22px/1.1em Museo;
	@media screen and (min-width: $tablet-width) {
		font-size: 32px;
	}
	@media screen and (min-width: $desktop-width) {
		font-size: 40px;
	}
}

.title-sm {
	font: 900 18px/1.1em Museo;
	@media screen and (min-width: $tablet-width) {
		font-size: 26px;
	}
}

//texts
.text-md {
	font: 500 14px/1.33em Museo;
	color: #494949;
	@media screen and (min-width: $tablet-width) {
		font-size: 14px;
	}
	@media screen and (min-width: $desktop-width) {
		font-size: 18px;
	}
}