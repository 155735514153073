.btn {
  display: inline-block;
  color:#fff;
  cursor: pointer;
  text-decoration: none;
  background-color: $main-green;
  border-radius: 100px;
  font: 900 14px/1em Museo;
  letter-spacing: 1.6px;
  text-align: center;
  padding: 15px 45px;
  text-transform: uppercase;
  width: 100%;
  transition:all .2s ease-in;
  @media screen and (min-width: $tablet-width) {
    width: auto;
  }
  &:hover {
    background-color: darken($main-green, 10%);
  }

}

.circle-btn {
  width: 40px;
  height: 40px;
  background-color: $main-green;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition:all .2s ease-in;
  &:hover {
    background-color: darken($main-green, 10%);
  }

}