.header {

    &__top {
        display: none;
        @media screen and (min-width: $tablet-width) {
            padding: 15px 0;
            color: $grey;
            font: italic 15px/1.2em Museo;
            border-bottom: 1px solid $light-grey;
            text-align: center;
            display: block;
        }
    }
    &__bottom {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media screen and (min-width: $tablet-width) {
            padding: 20px 0 80px;
        }
        @media screen and (min-width: $desktop-width) {
            padding: 20px 0;
        }
    }
    &__menu {
        @media screen and (max-width: $tablet-width) {
            transform:translateX(-120%);
            position: fixed;
            top: 0;
            left: 0;
            background: white;
            z-index: 100;
            height: 100%;
            padding: 30px 10px 20px;
            box-shadow: 5px 0 25px rgba(0,0,0,0.4);
            transition: transform .4s ease-in;
            ul {
                display: flex;
                flex-direction: column;
            }
            &._opened {
                transform: translateX(0);
            }
        }

        @media screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
            padding: 20px 0;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            margin: auto;
            text-align: center;
            border-top: 1px solid $light-grey;
        }

    }
    &__menu-item, &__menu li {
        font: 900 14px/1em Museo;
        letter-spacing: 0.5px;
        color: #000000;
        text-transform: uppercase;
        padding: 5px;
        display: inline-block;
        position: relative;
        margin-top: 15px;
        @media screen and (min-width: $tablet-width) {
            margin: 0;
            &:hover {
                .sub-menu {
                    display: block;
                }
            }
        }

    }
    &__menu li + li {
        @media screen and (min-width: $tablet-width) {
            margin-left: 15px;
        }
    }
    &__menu li a {
        transition: all .2s ease-in;
        &:hover {
            color: lighten(#000000, 20%);
        }
    }
    &__menu-item + &__menu-item {
        margin-left: 15px;
    }
    &__menu-icon {
        padding: 5px;
        cursor: pointer;
    }
    &__logo {
        background-image: url(assets/svg/logo.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
        width: 155px;
        height: 32px;
        @media screen and (min-width: $tablet-width) {
            width: 200px;
            height: 45px;
        }
    }
    &__contact {
        display: flex;
        flex-direction: column;
    }
    &__phone {
        font: 900 26px/1em Museo;
        color: $main-blue;
        &:hover {
            color: darken($main-blue, 10%);
        }
    }
    &__callback {
        padding: 5px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            width: 18px;
            height: 18px;
        }

    }
    &__callback-link {
        margin-left: 5px;
    }
}

.sub-menu {
    font-size: 13px;
    z-index: 99;
    margin-top: 10px;
    margin-left: 10px;
    @media screen and (min-width: $tablet-width) {
        background: #f3f3f3;
        border-radius: 10px;
        padding: 10px 5px;
        top: 22px;
        display: none;
        position: absolute;
        margin-top: 0;
        margin-left: 0;
    }

    li {
        font-size: 10px;
    }
    li + li {
        margin-left: 0;
        margin-top: 4px;
    }
}