.center-svg {
	position: relative;
}
.center, .center-svg > svg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

._hidden {
	display: none;
}