.works {
    background-image: url(assets/img/pattern-light.jpg);
    background-size: 50%;
    background-repeat: repeat;
    padding: 30px 0 0 0;
    text-align: center;
    @media screen and (min-width: $desktop-width) {
        text-align: left;

    }
    &__post {
        @media screen and (min-width: $desktop-width) {
            padding: 60px;
        }
    }
    &__text {
        margin: 15px 0;
        @media screen and (min-width: $tablet-width) {
            margin:20px auto;
            width: 60%;
        }
        @media screen and (min-width: $desktop-width) {
            width: auto;
        }
    }

    &__wrap {
        padding: 20px 0 80px;
        position: relative;
        &:after {
            content:'';
            position: absolute;
            bottom: 0;
            height: 53px;
            width: 220px;
            margin: auto;
            left: 0;
            right: 0;
            background-image: url(assets/svg/slider-curve-works.svg);
            -webkit-background-size: cover;
            background-size: cover;
            z-index: 1;
            background-repeat: no-repeat;
            @media screen and (min-width: $desktop-width) {
                //background-position: calc(50% - 20px) center;
                //height: 24px;
            }
        }
    }
    &__slider {
        height: 30vw;
        max-height:400px;
        @media screen and (min-width: $tablet-width) {
            height: 400px;
        }
    }
    &__banner {
        //background-image: url(assets/img/banner/banner-1.jpg);
        -webkit-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 80%;
        @media screen and (min-width: $desktop-width) {
            width: 100%;
        }
    }

    &__banner-link {
        position: absolute;
        background-color: #fff;
        bottom: 10px;
        padding:5px;
        left: 10px;
        border-radius: 5px;
    }

    &__slider-nav {
        position: absolute;
        width: 100%;
        bottom: 5px;
        display: flex;
        align-items: center;
        z-index: 2;
        justify-content: center;
    }
    &__nav-prev {
        svg {
            transform:scaleX(-1);
        }
    }
    &__slider-pag {
        width: 55px;
        text-align: center;
        font: italic 16px/1.13em Museo;
        color: rgba(0,0,0,0.4);

    }

}