@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/museosanscyrl_0-webfont.woff2') format('woff2'),
  url('assets/fonts/museosanscyrl_0-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/museosanscyrl_1-webfont.woff2') format('woff2'),
  url('assets/fonts/museosanscyrl_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/museosanscyrl_2-webfont.woff2') format('woff2'),
  url('assets/fonts/museosanscyrl_2-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/museosanscyrl_3-webfont.woff2') format('woff2'),
  url('assets/fonts/museosanscyrl_3-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('assets/fonts/museosanscyrl-webfont.woff2') format('woff2'),
  url('assets/fonts/museosanscyrl-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}