.category-header {
    padding: 20px 0 30px;    //background-image: linear-gradient(94deg, #4b80c8, rgba(75, 128, 200, 0));
    background-image: url(assets/img/water-bg.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    color:#fff;

    &__title {

        margin: 15px 0;
        color:#fff;
    }
    &__text {
        font: 500 14px/1.31em Museo;
    }
    @media screen and (min-width: $desktop-width) {
        padding: 60px 0 70px;

        &__title {
            margin: 23px 0;
        }
        &__text {
            width: 70%;
        }
    }
}

.category {
    @media screen and (min-width: $desktop-width) {
        padding: 30px 0 60px;
    }

    &__inner {
        padding: 23px 0;
        display: flex;
        flex-direction: row;
        @media screen and (min-width: $tablet-width) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__item + &__item {
        border-top:1px solid #d8d8d8;
        @media screen and (min-width: $tablet-width) {
            border-top: none;
        }
    }
    &__img {
        flex: 0 0 88px;
        width: 88px;
        height: 88px;
        border-radius: 10px;
        margin-right: 10px;
        position: relative;
        overflow: hidden;
        -webkit-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        img {
            display: block;
            max-width: 100%;
            max-height:100%;
        }
        @media screen and (min-width: $tablet-width) {
            flex: auto;
            width: 270px;
            height: 270px;

            margin-right: 0;
            margin-bottom: 10px;
            &:after {
                content:'';
                padding-bottom: 100%;
                display: table;

            }
        }
    }
    &__title {

    }
    &__description {
        font: italic 300 12px/1.29em Museo;
        opacity: 0.6;
        color: #000000;
        margin: 10px 0;
        @media screen and (min-width: $tablet-width) {
            font-size: 14px;
        }
    }
    &__price {
        margin-top: 10px;
        font: 500 20px/1em Museo;
        color: #000000;
        @media screen and (min-width: $tablet-width) {
            font-size: 24px;
        }
    }
    &_works {
        padding: 30px 0;
        //@media screen and (min-width: $tablet-width) {
        //    padding: 0;
        //}
        .category__inner {
            padding: 0;
        }
        .category__item p {
            display: inline;
        }

        .category__img {
            height: 170px;
            flex: 0 1 170px;
            min-width: 100px;
        }
        .category__link {
            display: block;
            margin-top: 30px;
        }

        @media screen and (min-width: $tablet-width) {
            .category__img {
                height: 170px;
                width: 100%;
                flex: 0 1 100%;
                min-width: 100px;
            }

            .category__item-link {
                margin-bottom: 20px;
                display: block;
            }
        }
        .category__item + .category__item {
            border:none;
        }

    }
    &__row + &__row {
        margin-top: 30px;
    }
    &__inner-link {
        margin-bottom: 30px;
        @media screen and (min-width: $tablet-width) {
            margin-bottom: 0;
        }
    }
}

.category-post {
    background-image: url(assets/img/pattern-light.jpg);
    background-size: 50%;
    background-repeat: repeat;
    padding: 20px 0;
    margin: 0;
    @media screen and (min-width: $tablet-width) {
        padding: 35px 0 60px;
    }

    h2 {
        font: 900 18px/1.1em Museo;
        @media screen and (min-width: $tablet-width) {
            font-size: 26px;
        }
    }
}