.breadcrumbs {
    font: 500 14px/1.31em Museo;
    color:#fff;
    &__item {
        display: inline-block;
        vertical-align: middle;
        border-bottom:1px solid rgba(255,255,255,0.5);
        &._active {
            border: none;
            pointer-events: none;
        }
    }
    &__item +  &__item {
        margin-left: 5px;
        &:before {
            content:'/';
            font-size: 14px;
            position: relative;
            left: -3px;
        }
    }
    &_light {
        color:#fff;
    }
}

.link {
    color:$main-blue;
    font: 500 14px/1.36em Museo;

    &__text {
        position: relative;
        border-bottom: 1px solid #4b80c8;
        display: inline;
    }

    &:hover {
        color: lighten($main-blue, 10%);
        .link__text {
            border-color: lighten(#4b80c8, 10%);
        }
    }
}
.form {
    p + p {
        margin-top: 10px;
    }
}
.input {
    border-radius: 10px;
    background-color: #f2f9fc;
    padding: 10px 6px;
    font: 500 14px/1.2em Museo;
    color: #88a5b1;
    width: 100%;
    height: 40px;
    + .input {
        margin-top: 10px;
    }
    + .btn {
        margin-top: 10px;
    }
    &_required {


    }
}
.your-tel, .your-name {
    position: relative;
    &:after {
        content:'*';
        font: 12px/1em Muse;
        color:$main-blue;
        right: 10px;
        top: 3px;
        position: absolute;
    }
}
.select {
    background: #f2f9fc;
    border: none;
    height: 40px;
    width: 100%;
    font: 500 14px/1.2em Museo;
    border-radius: 10px;
}
.textarea {
    @extend .input;
    height:125px;

}
.form {
    position: relative;
}
.wpcf7-validation-errors {
    margin: 0;
    padding: 0.2em 1em;
    position: absolute;
    bottom: -67px;
    background: white;
    display: none!important;
}
.wpcf7-not-valid {
    border: 1px solid rgba(red, 0.4);
    color: rgba(red, 0.4);
}
span.wpcf7-not-valid-tip {
    display: none!important;
}
.wpcf7-mail-sent-ok {
    display: none!important;
}


._modal-opened {
    overflow: hidden;
    padding-right: 17px;
}

.cross {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:hover {
        &:after, &:before {
            background-color: rgba(0,0,0,0.5);
        }
    }
    &:after, &:before {
        content:'';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000;
        top: 10px;
        left: 0;
        transition: all .3s ease-in;

    }
    &:after {
        transform:rotate(45deg);
    }
    &:before {
        transform:rotate(-45deg);
    }
}