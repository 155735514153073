.partners {
    padding: 20px 0;
    @media screen and (min-width: $tablet-width) {
        padding: 50px 0 30px;
    }
    @media screen and (min-width: $desktop-width) {
        padding: 90px 0 120px;
    }
    &__header-title {
        margin-bottom: 25px;
    }
    &__btn {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 35px;
        @media screen and (min-width: $tablet-width) {
            width: auto;
        }
        @media screen and (min-width: $desktop-width) {
            margin-bottom: 0;
        }
    }
    &__title {
        font: 500 17px/1em Museo;
        letter-spacing: 2.5px;
        color: #b2b2b2;
        text-transform: uppercase;
        margin-bottom: 15px;
        @media screen and (min-width: $tablet-width) {
            margin-bottom: 25px;

        }
    }
    &__wrap {
        @media screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    &__row {
        display: flex;
        //flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        //padding: 20px 0;
        border-top: solid 1px #dedede;
        @media screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
            flex: 0 1 50%;
            justify-content: flex-start;
            &:last-child {
                padding-left: 6%;
            }
        }
    }
    &__item +  &__item  {
        margin-left: 35px;
    }
    &__item {
        flex: 0 1 30%;
        padding: 20px 0;
        width: 80px;
        height: 40px;
        box-sizing: content-box;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            filter: grayscale(100%);
            transition: filter .3s ease-in;

        }
        &:hover {
            img {
                filter:none;
            }
        }
        @media screen and (min-width: $tablet-width) {
            width: 90px;
            height: 50px;
            padding: 30px 0;
        }
        @media screen and (min-width: $desktop-width) {
            width: 140px;
            height: 80px;
            padding: 35px 0;

        }
    }
}