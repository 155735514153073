.reviews {
    background-image: url(assets/img/pattern-light.jpg);
    background-size: 50%;
    background-repeat: repeat;
    padding: 20px 0;
    @media screen and (min-width: $tablet-width) {
        padding: 50px 0 35px;
    }
    @media screen and (min-width: $desktop-width) {
        padding: 100px 0 80px;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (min-width: $desktop-width) {
            margin-bottom: 40px;

        }
    }
    &__btn {
        display: flex;
        align-items: center;
    }
    &__btn-text {
        margin-right: 5px;
        font: 500 17px/1em Museo;
        position: relative;
        color: $main-green;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $main-green;
            opacity: .4;
            width: 100%;
            height: 1px;
        }
    }

    &__item {
        margin-bottom: 30px;

    }
    &__review {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 12px 24px 0 #e2f2f9;
        padding: 25px;
        position: relative;
        margin-bottom: 40px;
        &:after {
            content: '';
            position: absolute;
            bottom: -25px;
            left: 35px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 25px 0 0;
            border-color: #fff transparent transparent transparent;

        }
    }
    &__text {
        color: #3d3d3d;
        font: 500 14px/1.3em Museo;
        margin-bottom: 16px;
        img {
            display: none;
        }
    }
    &__img {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        img {
            display: block;
            object-fit: scale-down;
            max-height: 250px;
            width: 100%;
            margin-bottom: 20px;
        }
    }
    &__link {
        display: flex;
        align-items: center;
    }
    &__link-text {
        margin-left: 5px;
    }
    &__author {
        font: 900 16px/1em Museo;
        margin-bottom: 7px;

    }
    &__position {
        font: italic 500 16px/1em Museo;
        color: rgba(0, 0, 0, 0.4);
        @media screen and (min-width: $tablet-width) {
            font-size: 14px;
        }

    }
}