@import 'css/reset';
@import 'css/vars';
@import 'css/fonts';
@import 'css/grid';
@import 'css/buttons';
@import 'css/typo';
@import 'css/utilities';
@import 'css/elements';
@import 'css/post';
@import 'css/modal';



@import 'css/blocks/header';
@import 'css/blocks/footer';

@import 'css/blocks/banner';
@import 'css/blocks/services';
@import 'css/blocks/reviews';
@import 'css/blocks/partners';
@import 'css/blocks/works';


@import 'css/blocks/category';

@import 'css/blocks/products';

@import 'css/blocks/contacts';

@import 'css/blocks/pools-page';


body {
    font: 500 16px/1.3em Museo;
}

@import 'js/lib/swiper/css/swiper.min.css';
@import 'js/lib/fancybox/dist/jquery.fancybox.min.css';



//@import 'https://natyazhnoy-potolokpro.ru/assets/css/bootstrap.min.css';
//@import 'https://natyazhnoy-potolokpro.ru/assets/css/style.css';
//@import 'css/calc';


