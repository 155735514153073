@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url(js/lib/swiper/css/swiper.min.css);
@import url(js/lib/fancybox/dist/jquery.fancybox.min.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

button {
  background: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: currentColor; }

input, textarea {
  outline-offset: 0;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  resize: none;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */ }

input:focus, button:active, button:focus {
  outline: 0; }

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

template {
  display: none; }

[hidden] {
  display: none; }

html {
  -webkit-font-smoothing: antialiased; }

@font-face {
  font-family: 'Museo';
  src: url("assets/fonts/museosanscyrl_0-webfont.woff2") format("woff2"), url("assets/fonts/museosanscyrl_0-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Museo';
  src: url("assets/fonts/museosanscyrl_1-webfont.woff2") format("woff2"), url("assets/fonts/museosanscyrl_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Museo';
  src: url("assets/fonts/museosanscyrl_2-webfont.woff2") format("woff2"), url("assets/fonts/museosanscyrl_2-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Museo';
  src: url("assets/fonts/museosanscyrl_3-webfont.woff2") format("woff2"), url("assets/fonts/museosanscyrl_3-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Museo';
  src: url("assets/fonts/museosanscyrl-webfont.woff2") format("woff2"), url("assets/fonts/museosanscyrl-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 15px; }
  @media screen and (min-width: 768px) {
    .container {
      max-width: 1200px;
      padding: 0 20px; } }
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1200px;
      padding: 0 15px; } }

@media screen and (max-width: 768px) {
  .hidden-xs-down {
    display: none; } }

@media screen and (max-width: 1200px) {
  .hidden-sm-down {
    display: none; } }

@media screen and (min-width: 768px) {
  .hidden-sm-up {
    display: none; } }

@media screen and (min-width: 1200px) {
  .hidden-lg-up {
    display: none; } }

/* Grid */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .row.no-gutters > .column,
  .row.no-gutters > [class*="column-"] {
    padding-right: 0;
    padding-left: 0; }
  .row.gutters > .column,
  .row.gutters > [class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px; }

.col-xs-1 {
  position: relative;
  min-height: 1px;
  width: 8.33333%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-2 {
  position: relative;
  min-height: 1px;
  width: 16.66667%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-3 {
  position: relative;
  min-height: 1px;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-4 {
  position: relative;
  min-height: 1px;
  width: 33.33333%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-5 {
  position: relative;
  min-height: 1px;
  width: 41.66667%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-6 {
  position: relative;
  min-height: 1px;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-7 {
  position: relative;
  min-height: 1px;
  width: 58.33333%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-8 {
  position: relative;
  min-height: 1px;
  width: 66.66667%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-9 {
  position: relative;
  min-height: 1px;
  width: 75%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-10 {
  position: relative;
  min-height: 1px;
  width: 83.33333%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-11 {
  position: relative;
  min-height: 1px;
  width: 91.66667%;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-12 {
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 767px) {
  .col-sm-1 {
    position: relative;
    min-height: 1px;
    width: 8.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-2 {
    position: relative;
    min-height: 1px;
    width: 16.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-3 {
    position: relative;
    min-height: 1px;
    width: 25%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-4 {
    position: relative;
    min-height: 1px;
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-5 {
    position: relative;
    min-height: 1px;
    width: 41.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-6 {
    position: relative;
    min-height: 1px;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-7 {
    position: relative;
    min-height: 1px;
    width: 58.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-8 {
    position: relative;
    min-height: 1px;
    width: 66.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-9 {
    position: relative;
    min-height: 1px;
    width: 75%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-10 {
    position: relative;
    min-height: 1px;
    width: 83.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-11 {
    position: relative;
    min-height: 1px;
    width: 91.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm-12 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 1199px) {
  .col-lg-1 {
    position: relative;
    min-height: 1px;
    width: 8.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-2 {
    position: relative;
    min-height: 1px;
    width: 16.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-3 {
    position: relative;
    min-height: 1px;
    width: 25%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-4 {
    position: relative;
    min-height: 1px;
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-5 {
    position: relative;
    min-height: 1px;
    width: 41.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-6 {
    position: relative;
    min-height: 1px;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-7 {
    position: relative;
    min-height: 1px;
    width: 58.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-8 {
    position: relative;
    min-height: 1px;
    width: 66.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-9 {
    position: relative;
    min-height: 1px;
    width: 75%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-10 {
    position: relative;
    min-height: 1px;
    width: 83.33333%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-11 {
    position: relative;
    min-height: 1px;
    width: 91.66667%;
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

.btn {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  background-color: #44d4a8;
  border-radius: 100px;
  font: 900 14px/1em Museo;
  letter-spacing: 1.6px;
  text-align: center;
  padding: 15px 45px;
  text-transform: uppercase;
  width: 100%;
  transition: all .2s ease-in; }
  @media screen and (min-width: 768px) {
    .btn {
      width: auto; } }
  .btn:hover {
    background-color: #2bba8e; }

.circle-btn {
  width: 40px;
  height: 40px;
  background-color: #44d4a8;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: all .2s ease-in; }
  .circle-btn:hover {
    background-color: #2bba8e; }

.title-md {
  font: 900 22px/1.1em Museo; }
  @media screen and (min-width: 768px) {
    .title-md {
      font-size: 32px; } }
  @media screen and (min-width: 1200px) {
    .title-md {
      font-size: 40px; } }

.title-sm {
  font: 900 18px/1.1em Museo; }
  @media screen and (min-width: 768px) {
    .title-sm {
      font-size: 26px; } }

.text-md {
  font: 500 14px/1.33em Museo;
  color: #494949; }
  @media screen and (min-width: 768px) {
    .text-md {
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    .text-md {
      font-size: 18px; } }

.center-svg {
  position: relative; }

.center, .center-svg > svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

._hidden {
  display: none; }

.breadcrumbs {
  font: 500 14px/1.31em Museo;
  color: #fff; }
  .breadcrumbs__item {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .breadcrumbs__item._active {
      border: none;
      pointer-events: none; }
  .breadcrumbs__item + .breadcrumbs__item {
    margin-left: 5px; }
    .breadcrumbs__item + .breadcrumbs__item:before {
      content: '/';
      font-size: 14px;
      position: relative;
      left: -3px; }
  .breadcrumbs_light {
    color: #fff; }

.link {
  color: #4b80c8;
  font: 500 14px/1.36em Museo; }
  .link__text {
    position: relative;
    border-bottom: 1px solid #4b80c8;
    display: inline; }
  .link:hover {
    color: #729cd4; }
    .link:hover .link__text {
      border-color: #729cd4; }

.form p + p {
  margin-top: 10px; }

.input, .textarea {
  border-radius: 10px;
  background-color: #f2f9fc;
  padding: 10px 6px;
  font: 500 14px/1.2em Museo;
  color: #88a5b1;
  width: 100%;
  height: 40px; }
  .input + .input, .textarea + .input, .input + .textarea, .textarea + .textarea {
    margin-top: 10px; }
  .input + .btn, .textarea + .btn {
    margin-top: 10px; }

.your-tel, .your-name {
  position: relative; }
  .your-tel:after, .your-name:after {
    content: '*';
    font: 12px/1em Muse;
    color: #4b80c8;
    right: 10px;
    top: 3px;
    position: absolute; }

.select {
  background: #f2f9fc;
  border: none;
  height: 40px;
  width: 100%;
  font: 500 14px/1.2em Museo;
  border-radius: 10px; }

.textarea {
  height: 125px; }

.form {
  position: relative; }

.wpcf7-validation-errors {
  margin: 0;
  padding: 0.2em 1em;
  position: absolute;
  bottom: -67px;
  background: white;
  display: none !important; }

.wpcf7-not-valid {
  border: 1px solid rgba(255, 0, 0, 0.4);
  color: rgba(255, 0, 0, 0.4); }

span.wpcf7-not-valid-tip {
  display: none !important; }

.wpcf7-mail-sent-ok {
  display: none !important; }

._modal-opened {
  overflow: hidden;
  padding-right: 17px; }

.cross {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer; }
  .cross:hover:after, .cross:hover:before {
    background-color: rgba(0, 0, 0, 0.5); }
  .cross:after, .cross:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
    top: 10px;
    left: 0;
    transition: all .3s ease-in; }
  .cross:after {
    transform: rotate(45deg); }
  .cross:before {
    transform: rotate(-45deg); }

.post {
  margin: 25px 0;
  overflow: hidden; }
  .post p {
    font-family: Museo;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.29em;
    text-align: left;
    color: #3d3d3d; }
    .post p + p {
      margin-top: 1em; }
  .post a {
    color: #4b80c8; }
    .post a:hover {
      color: #729cd4; }
  .post__annotation {
    font: 300 16px/1.33em Museo;
    color: #494949;
    display: block;
    margin: 25px 0; }
    @media screen and (min-width: 768px) {
      .post__annotation {
        font-size: 16px; } }
    @media screen and (min-width: 1200px) {
      .post__annotation {
        font-size: 18px; } }
  .post__title-md, .post h2 {
    margin: 30px 0 20px;
    font: 900 22px/1.1em Museo; }
    @media screen and (min-width: 768px) {
      .post__title-md, .post h2 {
        font-size: 32px; } }
    @media screen and (min-width: 1200px) {
      .post__title-md, .post h2 {
        font-size: 40px; } }
  .post__title-sm, .post h3 {
    margin: 25px 0 15px;
    font: 900 18px/1.1em Museo; }
    @media screen and (min-width: 768px) {
      .post__title-sm, .post h3 {
        font-size: 20px; } }
    @media screen and (min-width: 1200px) {
      .post__title-sm, .post h3 {
        font-size: 23px; } }
  .post__title-sm, .post h4 {
    margin: 20px 0 10px;
    font: 900 16px/1.1em Museo; }
    @media screen and (min-width: 768px) {
      .post__title-sm, .post h4 {
        font-size: 18px; } }
    @media screen and (min-width: 1200px) {
      .post__title-sm, .post h4 {
        font-size: 20px; } }
  .post blockquote {
    background-color: #e8f5fa;
    background-image: url(assets/img/pattern-light.jpg);
    background-size: 50%;
    background-repeat: repeat;
    padding: 30px 30px 30px 90px;
    font: 500 16px/1.33em Museo;
    color: #494949;
    position: relative;
    border-radius: 10px;
    margin: 45px 0px;
    clear: both; }
    .post blockquote p {
      font: 300 16px/1.33em Museo;
      color: #494949; }
      @media screen and (min-width: 768px) {
        .post blockquote p {
          font-size: 16px; } }
      @media screen and (min-width: 1200px) {
        .post blockquote p {
          font-size: 18px; } }
    @media screen and (min-width: 768px) {
      .post blockquote {
        font-size: 16px; } }
    @media screen and (min-width: 1200px) {
      .post blockquote {
        font-size: 18px; } }
    .post blockquote:before {
      content: "";
      background-image: url(assets/svg/quote.svg);
      position: absolute;
      left: 25px;
      top: 25px;
      width: 39px;
      height: 39px;
      background-repeat: no-repeat; }
  .post ul {
    margin: 20px 0; }
    .post ul li {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.29;
      color: #3d3d3d;
      padding-left: 33px;
      margin: 4px 0;
      position: relative; }
      .post ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        width: 24px;
        height: 1px;
        background-color: #44d4a8; }
  .post ol {
    margin: 20px 0;
    counter-reset: li; }
    .post ol li {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.29;
      color: #3d3d3d;
      padding-left: 33px;
      margin: 4px 0;
      position: relative; }
      .post ol li:before {
        counter-increment: li;
        content: "" counter(li) ". ";
        position: absolute;
        left: 15px; }
  .post .wp-caption.alignright, .post .alignright {
    margin: 30px 0;
    object-fit: contain;
    max-height: 500px;
    width: 100%; }
    @media screen and (min-width: 1200px) {
      .post .wp-caption.alignright, .post .alignright {
        float: right;
        margin: 0 0 30px 30px;
        width: 45%; } }
  .post .wp-caption.alignleft, .post .alignleft {
    margin: 30px 0;
    object-fit: contain;
    max-height: 500px;
    width: 100%; }
    @media screen and (min-width: 1200px) {
      .post .wp-caption.alignleft, .post .alignleft {
        float: left;
        margin: 0 30px 30px 0;
        width: 45%; } }
  .post .wp-caption-text {
    text-align: center;
    font-style: italic;
    padding-top: 5px; }

.modal {
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .modal._shown {
    display: flex; }
    .modal._shown .modal__wrapper {
      transform: scale(1);
      opacity: 1;
      transition: all 0.25s ease-in; }
  .modal__close {
    width: 30px;
    height: 30px;
    top: 20px; }
  .modal__wrapper {
    position: relative;
    background: #fff;
    width: 425px;
    max-width: 1300px;
    text-align: center;
    transform: scale(0.3);
    opacity: 0;
    transition: all 0.25s ease-in;
    backface-visibility: hidden;
    margin: auto;
    padding: 55px; }
  .modal__form {
    margin-top: 30px;
    text-align: center; }
    .modal__form p + p {
      margin-top: 10px; }
  .modal__agree {
    margin-top: 10px;
    font: italic 300 14px/1.29em Museo;
    opacity: .6;
    color: #000; }

.header__top {
  display: none; }
  @media screen and (min-width: 768px) {
    .header__top {
      padding: 15px 0;
      color: #b0b0b0;
      font: italic 15px/1.2em Museo;
      border-bottom: 1px solid #d7d7d7;
      text-align: center;
      display: block; } }

.header__bottom {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  @media screen and (min-width: 768px) {
    .header__bottom {
      padding: 20px 0 80px; } }
  @media screen and (min-width: 1200px) {
    .header__bottom {
      padding: 20px 0; } }

@media screen and (max-width: 768px) {
  .header__menu {
    transform: translateX(-120%);
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 100;
    height: 100%;
    padding: 30px 10px 20px;
    box-shadow: 5px 0 25px rgba(0, 0, 0, 0.4);
    transition: transform .4s ease-in; }
    .header__menu ul {
      display: flex;
      flex-direction: column; }
    .header__menu._opened {
      transform: translateX(0); } }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .header__menu {
    padding: 20px 0;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin: auto;
    text-align: center;
    border-top: 1px solid #d7d7d7; } }

.header__menu-item, .header__menu li {
  font: 900 14px/1em Museo;
  letter-spacing: 0.5px;
  color: #000000;
  text-transform: uppercase;
  padding: 5px;
  display: inline-block;
  position: relative;
  margin-top: 15px; }
  @media screen and (min-width: 768px) {
    .header__menu-item, .header__menu li {
      margin: 0; }
      .header__menu-item:hover .sub-menu, .header__menu li:hover .sub-menu {
        display: block; } }

@media screen and (min-width: 768px) {
  .header__menu li + li {
    margin-left: 15px; } }

.header__menu li a {
  transition: all .2s ease-in; }
  .header__menu li a:hover {
    color: #333333; }

.header__menu-item + .header__menu-item {
  margin-left: 15px; }

.header__menu-icon {
  padding: 5px;
  cursor: pointer; }

.header__logo {
  background-image: url(assets/svg/logo.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  width: 155px;
  height: 32px; }
  @media screen and (min-width: 768px) {
    .header__logo {
      width: 200px;
      height: 45px; } }

.header__contact {
  display: flex;
  flex-direction: column; }

.header__phone {
  font: 900 26px/1em Museo;
  color: #4b80c8; }
  .header__phone:hover {
    color: #3467ac; }

.header__callback {
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .header__callback svg {
    width: 18px;
    height: 18px; }

.header__callback-link {
  margin-left: 5px; }

.sub-menu {
  font-size: 13px;
  z-index: 99;
  margin-top: 10px;
  margin-left: 10px; }
  @media screen and (min-width: 768px) {
    .sub-menu {
      background: #f3f3f3;
      border-radius: 10px;
      padding: 10px 5px;
      top: 22px;
      display: none;
      position: absolute;
      margin-top: 0;
      margin-left: 0; } }
  .sub-menu li {
    font-size: 10px; }
  .sub-menu li + li {
    margin-left: 0;
    margin-top: 4px; }

.footer {
  background-color: #304864;
  background-image: url(/wp-content/themes/bricks/assets/img/pattern-dark.jpg);
  -webkit-background-size: 50%;
  background-size: 50%; }
  @media screen and (min-width: 1200px) {
    .footer__wrap {
      display: flex;
      padding-top: 30px; } }
  @media screen and (min-width: 1200px) {
    .footer__inner {
      padding-right: 40px; } }
  .footer__top {
    padding: 30px 0;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .footer__top {
        display: flex;
        align-items: flex-start;
        text-align: left;
        justify-content: space-between; } }
    @media screen and (min-width: 1200px) {
      .footer__top {
        padding-top: 0;
        padding-bottom: 20px;
        align-items: center; } }
  .footer__logo {
    background-image: url(/wp-content/themes/bricks/assets/svg/footer_logo.svg);
    background-repeat: no-repeat;
    background-size: 90%;
    display: inline-block;
    width: 220px;
    height: 47px; }
  .footer__menu {
    padding: 30px 0 40px; }
    @media screen and (min-width: 768px) {
      .footer__menu {
        padding: 0; } }
  .footer__menu-item, .footer__menu li {
    font: 900 13px/1em Museo;
    letter-spacing: 0.5px;
    color: #fff;
    padding: 3px;
    text-transform: uppercase;
    display: block; }
    @media screen and (min-width: 1200px) {
      .footer__menu-item, .footer__menu li {
        display: inline-block; } }
  .footer__menu-item + .footer__menu-item, .footer__menu li + li {
    margin-top: 14px; }
    @media screen and (min-width: 1200px) {
      .footer__menu-item + .footer__menu-item, .footer__menu li + li {
        margin-top: 0;
        margin-left: 14px; } }
  .footer__menu li a:hover {
    color: #cccccc; }
  .footer__phone {
    font: 900 25px/1em Museo;
    color: #fff; }
    @media screen and (min-width: 1200px) {
      .footer__phone {
        font-size: 29px; } }
  .footer__email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 18px; }
    @media screen and (min-width: 1200px) {
      .footer__email {
        justify-content: flex-start; } }
  .footer__email-link {
    font: 500 14px/1em Museo;
    color: #fff;
    position: relative;
    margin-left: 8px; }
    .footer__email-link:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      width: 100%;
      left: 0; }
  .footer__btn {
    width: 100%; }
  .footer__bottom {
    text-align: center;
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font: 500 13px/1.46em Museo;
    color: rgba(255, 255, 255, 0.5); }
    @media screen and (min-width: 1200px) {
      .footer__bottom {
        text-align: left;
        padding: 20px 0; } }

.banner {
  color: #fff;
  position: relative; }
  @media screen and (max-width: 768px) {
    .banner .container {
      padding: 0; } }
  .banner__wrap {
    height: 250px; }
    .banner__wrap:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 20px;
      width: 100%;
      background-image: url(assets/svg/slider-curve.svg);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: calc(50% - 23px) center;
      z-index: 1;
      background-repeat: no-repeat; }
    @media screen and (min-width: 768px) {
      .banner__wrap {
        height: 500px; } }
  .banner__cover {
    background-image: url(assets/img/banner/banner-1.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 0; }
    .banner__cover:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.3); }
  .banner__info {
    text-align: center;
    padding: 40px 40px 70px 40px;
    box-sizing: border-box; }
    @media screen and (min-width: 768px) {
      .banner__info {
        text-align: left;
        padding: 100px 0;
        width: 40%; } }
  .banner__title {
    font: 900 22px/1em Museo;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      .banner__title {
        font-size: 32px; } }
    @media screen and (min-width: 1200px) {
      .banner__title {
        font-size: 52px;
        margin-bottom: 15px; } }
  .banner__descr {
    font: 500 14px/1.14em Museo;
    margin-bottom: 19px; }
    @media screen and (min-width: 768px) {
      .banner__descr {
        font-size: 18px;
        line-height: 1.38em; } }
    @media screen and (min-width: 1200px) {
      .banner__descr {
        margin-bottom: 28px; } }
  .banner .swiper-pagination-bullets {
    display: block;
    max-width: 80px;
    position: absolute;
    width: auto;
    margin: 0 auto;
    border-radius: 100px;
    background-color: #f2f9fc;
    -webkit-box-shadow: 0 2px 11px 0 rgba(136, 190, 217, 0.47);
    box-shadow: 0 2px 11px 0 rgba(136, 190, 217, 0.47);
    bottom: 6px;
    right: 0;
    left: 0;
    z-index: 10;
    padding: 3px; }
  .banner .swiper-pagination-bullet {
    width: 11.2px;
    height: 11.2px;
    background-color: #c9dbe2;
    margin: 0 3px !important; }
  .banner .swiper-pagination-bullet-active {
    background-color: #44d4a8; }

.services {
  margin: 10px 0; }
  @media screen and (min-width: 768px) {
    .services {
      margin: 50px 0 25px; } }
  @media screen and (min-width: 1200px) {
    .services {
      margin: 70px 0 40px; } }
  .services__description {
    text-align: center; }
    @media screen and (min-width: 1200px) {
      .services__description {
        text-align: left; } }
  .services__title {
    margin-bottom: 20px; }
  .services__text {
    font-weight: 300; }

.cart-item {
  background-color: #f3f9fc;
  border-radius: 10px;
  display: block;
  position: relative;
  padding: 20px 20px 80px;
  background-repeat: no-repeat;
  background-position: right bottom;
  -webkit-background-size: cover;
  background-size: cover;
  height: 150px; }
  @media screen and (min-width: 768px) {
    .cart-item {
      height: 290px;
      padding: 30px 30px 80px; } }
  @media screen and (min-width: 1200px) {
    .cart-item {
      padding: 40px 40px 80px; } }
  .cart-item_lg {
    background-image: url(../assets/img/services/services-1.jpg);
    background-position: center center; }
  .cart-item__title {
    color: #4b80c8;
    font: 300 18px/1.18em Museo; }
    @media screen and (min-width: 768px) {
      .cart-item__title {
        font-size: 22px; } }
  .cart-item__btn {
    position: absolute;
    bottom: 20px;
    left: 20px; }
    @media screen and (min-width: 768px) {
      .cart-item__btn {
        bottom: 30px;
        left: 30px; } }
    @media screen and (min-width: 1200px) {
      .cart-item__btn {
        bottom: 40px;
        left: 40px; } }

.seo-post {
  margin: 30px 0; }
  .seo-post__title {
    margin-bottom: 20px; }
  .seo-post__text {
    font: 500 14px/1.33em Museo;
    color: #3d3d3d; }

.reviews {
  background-image: url(assets/img/pattern-light.jpg);
  background-size: 50%;
  background-repeat: repeat;
  padding: 20px 0; }
  @media screen and (min-width: 768px) {
    .reviews {
      padding: 50px 0 35px; } }
  @media screen and (min-width: 1200px) {
    .reviews {
      padding: 100px 0 80px; } }
  .reviews__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 1200px) {
      .reviews__header {
        margin-bottom: 40px; } }
  .reviews__btn {
    display: flex;
    align-items: center; }
  .reviews__btn-text {
    margin-right: 5px;
    font: 500 17px/1em Museo;
    position: relative;
    color: #44d4a8; }
    .reviews__btn-text:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #44d4a8;
      opacity: .4;
      width: 100%;
      height: 1px; }
  .reviews__item {
    margin-bottom: 30px; }
  .reviews__review {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 12px 24px 0 #e2f2f9;
    padding: 25px;
    position: relative;
    margin-bottom: 40px; }
    .reviews__review:after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 25px 0 0;
      border-color: #fff transparent transparent transparent; }
  .reviews__text {
    color: #3d3d3d;
    font: 500 14px/1.3em Museo;
    margin-bottom: 16px; }
    .reviews__text img {
      display: none; }
  .reviews__img {
    display: block;
    margin-bottom: 20px;
    text-align: center; }
    .reviews__img img {
      display: block;
      object-fit: scale-down;
      max-height: 250px;
      width: 100%;
      margin-bottom: 20px; }
  .reviews__link {
    display: flex;
    align-items: center; }
  .reviews__link-text {
    margin-left: 5px; }
  .reviews__author {
    font: 900 16px/1em Museo;
    margin-bottom: 7px; }
  .reviews__position {
    font: italic 500 16px/1em Museo;
    color: rgba(0, 0, 0, 0.4); }
    @media screen and (min-width: 768px) {
      .reviews__position {
        font-size: 14px; } }

.partners {
  padding: 20px 0; }
  @media screen and (min-width: 768px) {
    .partners {
      padding: 50px 0 30px; } }
  @media screen and (min-width: 1200px) {
    .partners {
      padding: 90px 0 120px; } }
  .partners__header-title {
    margin-bottom: 25px; }
  .partners__btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 35px; }
    @media screen and (min-width: 768px) {
      .partners__btn {
        width: auto; } }
    @media screen and (min-width: 1200px) {
      .partners__btn {
        margin-bottom: 0; } }
  .partners__title {
    font: 500 17px/1em Museo;
    letter-spacing: 2.5px;
    color: #b2b2b2;
    text-transform: uppercase;
    margin-bottom: 15px; }
    @media screen and (min-width: 768px) {
      .partners__title {
        margin-bottom: 25px; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .partners__wrap {
      display: flex;
      flex-wrap: nowrap; } }
  .partners__row {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #dedede; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .partners__row {
        flex: 0 1 50%;
        justify-content: flex-start; }
        .partners__row:last-child {
          padding-left: 6%; } }
  .partners__item + .partners__item {
    margin-left: 35px; }
  .partners__item {
    flex: 0 1 30%;
    padding: 20px 0;
    width: 80px;
    height: 40px;
    box-sizing: content-box;
    position: relative; }
    .partners__item img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      filter: grayscale(100%);
      transition: filter .3s ease-in; }
    .partners__item:hover img {
      filter: none; }
    @media screen and (min-width: 768px) {
      .partners__item {
        width: 90px;
        height: 50px;
        padding: 30px 0; } }
    @media screen and (min-width: 1200px) {
      .partners__item {
        width: 140px;
        height: 80px;
        padding: 35px 0; } }

.works {
  background-image: url(assets/img/pattern-light.jpg);
  background-size: 50%;
  background-repeat: repeat;
  padding: 30px 0 0 0;
  text-align: center; }
  @media screen and (min-width: 1200px) {
    .works {
      text-align: left; } }
  @media screen and (min-width: 1200px) {
    .works__post {
      padding: 60px; } }
  .works__text {
    margin: 15px 0; }
    @media screen and (min-width: 768px) {
      .works__text {
        margin: 20px auto;
        width: 60%; } }
    @media screen and (min-width: 1200px) {
      .works__text {
        width: auto; } }
  .works__wrap {
    padding: 20px 0 80px;
    position: relative; }
    .works__wrap:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 53px;
      width: 220px;
      margin: auto;
      left: 0;
      right: 0;
      background-image: url(assets/svg/slider-curve-works.svg);
      -webkit-background-size: cover;
      background-size: cover;
      z-index: 1;
      background-repeat: no-repeat; }
  .works__slider {
    height: 30vw;
    max-height: 400px; }
    @media screen and (min-width: 768px) {
      .works__slider {
        height: 400px; } }
  .works__banner {
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    width: 80%; }
    @media screen and (min-width: 1200px) {
      .works__banner {
        width: 100%; } }
  .works__banner-link {
    position: absolute;
    background-color: #fff;
    bottom: 10px;
    padding: 5px;
    left: 10px;
    border-radius: 5px; }
  .works__slider-nav {
    position: absolute;
    width: 100%;
    bottom: 5px;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center; }
  .works__nav-prev svg {
    transform: scaleX(-1); }
  .works__slider-pag {
    width: 55px;
    text-align: center;
    font: italic 16px/1.13em Museo;
    color: rgba(0, 0, 0, 0.4); }

.category-header {
  padding: 20px 0 30px;
  background-image: url(assets/img/water-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff; }
  .category-header__title {
    margin: 15px 0;
    color: #fff; }
  .category-header__text {
    font: 500 14px/1.31em Museo; }
  @media screen and (min-width: 1200px) {
    .category-header {
      padding: 60px 0 70px; }
      .category-header__title {
        margin: 23px 0; }
      .category-header__text {
        width: 70%; } }

@media screen and (min-width: 1200px) {
  .category {
    padding: 30px 0 60px; } }

.category__inner {
  padding: 23px 0;
  display: flex;
  flex-direction: row; }
  @media screen and (min-width: 768px) {
    .category__inner {
      flex-direction: column;
      align-items: flex-start; } }

.category__item + .category__item {
  border-top: 1px solid #d8d8d8; }
  @media screen and (min-width: 768px) {
    .category__item + .category__item {
      border-top: none; } }

.category__img {
  flex: 0 0 88px;
  width: 88px;
  height: 88px;
  border-radius: 10px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .category__img img {
    display: block;
    max-width: 100%;
    max-height: 100%; }
  @media screen and (min-width: 768px) {
    .category__img {
      flex: auto;
      width: 270px;
      height: 270px;
      margin-right: 0;
      margin-bottom: 10px; }
      .category__img:after {
        content: '';
        padding-bottom: 100%;
        display: table; } }

.category__description {
  font: italic 300 12px/1.29em Museo;
  opacity: 0.6;
  color: #000000;
  margin: 10px 0; }
  @media screen and (min-width: 768px) {
    .category__description {
      font-size: 14px; } }

.category__price {
  margin-top: 10px;
  font: 500 20px/1em Museo;
  color: #000000; }
  @media screen and (min-width: 768px) {
    .category__price {
      font-size: 24px; } }

.category_works {
  padding: 30px 0; }
  .category_works .category__inner {
    padding: 0; }
  .category_works .category__item p {
    display: inline; }
  .category_works .category__img {
    height: 170px;
    flex: 0 1 170px;
    min-width: 100px; }
  .category_works .category__link {
    display: block;
    margin-top: 30px; }
  @media screen and (min-width: 768px) {
    .category_works .category__img {
      height: 170px;
      width: 100%;
      flex: 0 1 100%;
      min-width: 100px; }
    .category_works .category__item-link {
      margin-bottom: 20px;
      display: block; } }
  .category_works .category__item + .category__item {
    border: none; }

.category__row + .category__row {
  margin-top: 30px; }

.category__inner-link {
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .category__inner-link {
      margin-bottom: 0; } }

.category-post {
  background-image: url(assets/img/pattern-light.jpg);
  background-size: 50%;
  background-repeat: repeat;
  padding: 20px 0;
  margin: 0; }
  @media screen and (min-width: 768px) {
    .category-post {
      padding: 35px 0 60px; } }
  .category-post h2 {
    font: 900 18px/1.1em Museo; }
    @media screen and (min-width: 768px) {
      .category-post h2 {
        font-size: 26px; } }

.product-page {
  padding: 10px 0 40px; }
  .product-page__title {
    margin: 15px 0 20px; }
  .product-page .breadcrumbs {
    color: #4b80c8; }
    .product-page .breadcrumbs .breadcrumbs__item {
      border-bottom: solid 1px rgba(75, 128, 200, 0.5); }
      .product-page .breadcrumbs .breadcrumbs__item._active {
        border: none;
        pointer-events: none; }
  .product-page__item {
    margin: 22px 0;
    display: block; }
    @media screen and (min-width: 768px) {
      .product-page__item {
        margin: 0; } }
  .product-page__img {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 100%; }
    .product-page__img img {
      max-width: 100%;
      max-height: 100%;
      display: block; }
  .product-page__review-img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .product-page__label {
    font: 500 12px/1.4em Museo;
    letter-spacing: 1.8px;
    color: #6f6f6f; }
  .product-page__price {
    margin-top: 5px;
    font: 500 37px/1em Museo;
    color: #000000; }
  .product-page__btn {
    width: 100%;
    margin-top: 16px; }
    @media screen and (min-width: 1200px) {
      .product-page__btn {
        flex: 0 0 200px; } }
  .product-page__post {
    margin: 0; }
    @media screen and (min-width: 768px) {
      .product-page__post {
        padding: 30px;
        background-image: url(assets/img/pattern-light.jpg);
        background-size: 50%;
        background-repeat: repeat;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin: 0; } }
  .product-page__annotation {
    font: 300 18px/1.3em Museo;
    color: #494949;
    margin: 25px 0; }
  @media screen and (min-width: 768px) {
    .product-page__text {
      font-size: 14px;
      line-height: 1.29em; } }
  @media screen and (min-width: 1200px) {
    .product-page__order {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; } }

.contacts {
  position: relative; }
  .contacts__block {
    padding: 23px 0 60px;
    background-color: #fff; }
    @media screen and (min-width: 768px) {
      .contacts__block {
        padding: 35px 40px;
        position: absolute;
        top: 30px;
        width: 290px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px; } }
    @media screen and (min-width: 1200px) {
      .contacts__block {
        width: 370px; } }
    .contacts__block .breadcrumbs {
      color: #4b80c8; }
      .contacts__block .breadcrumbs .breadcrumbs__item {
        border-bottom: solid 1px rgba(75, 128, 200, 0.5); }
        .contacts__block .breadcrumbs .breadcrumbs__item._active {
          border: none;
          pointer-events: none; }
  .contacts__map {
    height: 250px; }
    @media screen and (min-width: 768px) {
      .contacts__map {
        height: 750px; } }
  .contacts__title {
    margin: 20px 0 30px; }
  .contacts__line {
    display: flex;
    align-items: flex-start; }
    .contacts__line._mail svg path {
      fill: #4b80c8; }
    .contacts__line svg {
      margin-right: 15px; }
  .contacts__line + .contacts__line {
    margin-top: 18px; }
  .contacts__info {
    font: 500 14px/1.33em Museo; }
  .contacts__form-block {
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: 1px solid #dfe7eb; }
  .contacts__form-title {
    font: 500 13px/1.33em Museo;
    letter-spacing: 1.9px;
    color: #3d3d3d; }
  .contacts__form {
    margin: 30px 0 0 0; }
  .contacts__form-btn {
    margin-top: 10px;
    width: 100%; }

.teaser {
  padding: 30px 0;
  background-color: rgba(75, 128, 200, 0.65);
  background-image: url(assets/img/water-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff; }
  .teaser_ban {
    background-image: url(assets/img/sauna/main-bg.png);
    min-height: 300px; }
  @media screen and (min-width: 768px) {
    .teaser {
      padding: 40px 0; } }
  @media screen and (min-width: 768px) {
    .teaser__info {
      width: 50%; } }
  .teaser__title {
    margin: 15px 0; }
  .teaser__descr {
    margin-bottom: 19px; }
    @media screen and (min-width: 1200px) {
      .teaser__descr {
        margin-bottom: 28px; } }

.advantages {
  padding: 25px 0;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .advantages {
      padding: 45px 0; } }
  @media screen and (min-width: 768px) {
    .advantages__row:nth-child(even) {
      flex-direction: row-reverse; } }
  .advantages__row + .advantages__row {
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .advantages__row + .advantages__row {
        margin-top: 45px; } }
  @media screen and (max-width: 1200px) {
    .advantages__row:last-child {
      margin-top: 0; } }
  @media screen and (min-width: 1200px) {
    .advantages__title {
      margin-top: 40px; } }
  .advantages__main-text {
    margin: 30px 0; }
  .advantages__wrap {
    background-color: #f3f9fc;
    border-radius: 10px;
    padding: 22px 25px; }
    @media screen and (min-width: 1200px) {
      .advantages__wrap {
        padding: 45px; } }
  .advantages__line + .advantages__line {
    margin-top: 25px; }
  .advantages__line {
    display: flex;
    align-items: flex-start;
    text-align: left; }
    .advantages__line svg {
      flex: 0 0 55px;
      width: 54px;
      height: 46px;
      margin-right: 18px; }
  .advantages__text {
    color: #4b80c8;
    font: 500 14px/1.3em Museo; }
  .advantages__img img {
    max-width: 100%;
    max-height: 100%;
    display: block; }
  .advantages__img {
    margin-bottom: 30px; }
    @media screen and (min-width: 1200px) {
      .advantages__img {
        margin-bottom: 0; } }
    .advantages__img img {
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px; }

.calculate {
  margin: 30px 0; }
  .calculate__inner {
    padding: 27px 20px;
    background-image: url(assets/img/water-bg.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    margin: 0 -15px; }
    @media screen and (min-width: 768px) {
      .calculate__inner {
        padding: 60px 65px;
        margin: 0;
        border-radius: 10px; } }
    @media screen and (min-width: 1200px) {
      .calculate__inner {
        padding: 90px 65px; } }
  .calculate__text {
    margin: 10px 0 20px; }
  .calculate__form-alarm {
    opacity: 0.7;
    font-family: Museo;
    font-size: 14px;
    font-style: italic;
    text-align: left;
    color: #ffffff;
    margin-top: 10px; }
    .calculate__form-alarm a {
      text-decoration: underline; }
  .calculate .wpcf7-form-control-wrap:nth-child(1) .calculate__form-input {
    border-radius: 10px 0 0 10px;
    border-right: 1px solid; }
  .calculate .wpcf7-form-control-wrap:nth-child(2) .calculate__form-input {
    border-radius: 0;
    position: relative; }
    .calculate .wpcf7-form-control-wrap:nth-child(2) .calculate__form-input:after {
      content: '×';
      position: absolute;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.2); }
  .calculate .wpcf7-form-control-wrap:nth-child(3) .calculate__form-input {
    border-left: 1px solid;
    border-radius: 0 10px 10px 0; }
  .calculate__form-input {
    float: left;
    width: 33.33%;
    margin: 0 !important; }
  .calculate__form-row + .calculate__form-row {
    margin-top: 10px; }
  .calculate__form-btn {
    width: 100%; }
  @media screen and (max-width: 768px) {
    .calculate__form-row:last-child {
      margin-top: 0; }
    .calculate__form-row input {
      margin-bottom: 10px; }
    .calculate__form-row select {
      margin-top: 10px; } }

.steps {
  margin: 30px 0; }
  .steps__wrap {
    margin: 30px 0; }
    @media screen and (min-width: 768px) {
      .steps__wrap {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        padding: 0 30px; } }
    @media screen and (min-width: 1200px) {
      .steps__wrap {
        padding: 0;
        flex-wrap: nowrap;
        margin: 50px 0 0 0; } }
  .steps__title {
    text-align: center; }
  .steps__row {
    align-items: center;
    display: flex; }
    .steps__row svg {
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin-right: 20px; }
    @media screen and (min-width: 768px) {
      .steps__row {
        flex-direction: column; }
        .steps__row svg {
          margin-right: 0;
          margin-bottom: 10px;
          flex: 0 0 130px;
          width: 130px;
          height: 130px; } }
  .steps__row + .steps__row {
    margin-top: 10px; }
    @media screen and (min-width: 768px) {
      .steps__row + .steps__row {
        margin-top: 0; } }
  @media screen and (min-width: 768px) {
    .steps__row {
      margin-bottom: 30px;
      position: relative; }
      .steps__row:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 15px;
        background-color: #44d4a8;
        right: 0;
        top: 30%; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .steps__row:nth-child(3n):after {
      content: none; } }
  @media screen and (min-width: 1200px) {
    .steps__row:last-child:after {
      content: none; } }
  @media screen and (min-width: 768px) {
    .steps__text {
      padding: 0 60px; } }
  @media screen and (min-width: 1200px) {
    .steps__text {
      padding: 0 30px; } }

.sauna-types__title {
  margin: 10px 0;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .sauna-types__title {
      margin: 20px 0; } }

body {
  font: 500 16px/1.3em Museo; }
