.post {
    margin: 25px 0;
    overflow: hidden;

    p {
        font-family: Museo;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.29em;
        text-align: left;
        color: #3d3d3d;
        + p {
            margin-top: 1em;
        }
    }
    a {
        color:$main-blue;
        &:hover {
            color: lighten($main-blue, 10%);
        }
    }

    &__annotation {
        font: 300 16px/1.33em Museo;
        color: #494949;
        display: block;
        margin: 25px 0;
        @media screen and (min-width: $tablet-width) {
            font-size: 16px;
        }
        @media screen and (min-width: $desktop-width) {
            font-size: 18px;
        }
    }

    &__title-md, h2 {
        margin: 30px 0 20px;
        font: 900 22px/1.1em Museo;
        @media screen and (min-width: $tablet-width) {
            font-size: 32px;
        }
        @media screen and (min-width: $desktop-width) {
            font-size: 40px;
        }
    }
    &__title-sm, h3 {
        margin: 25px 0 15px;
        font: 900 18px/1.1em Museo;
        @media screen and (min-width: $tablet-width) {
            font-size: 20px;
        }
        @media screen and (min-width: $desktop-width) {
            font-size: 23px;
        }
    }
    &__title-sm, h4 {
        margin: 20px 0 10px;
        font: 900 16px/1.1em Museo;
        @media screen and (min-width: $tablet-width) {
            font-size: 18px;
        }
        @media screen and (min-width: $desktop-width) {
            font-size: 20px;
        }
    }

    blockquote {
        background-color: #e8f5fa;
        background-image: url(assets/img/pattern-light.jpg);
        background-size: 50%;
        background-repeat: repeat;
        padding: 30px 30px 30px 90px;
        font: 500 16px/1.33em Museo;
        color: #494949;
        position: relative;
        border-radius: 10px;
        margin: 45px 0px;
        clear: both;
        p {
            font: 300 16px/1.33em Museo;
            color: #494949;
            @media screen and (min-width: $tablet-width) {
                font-size: 16px;
            }
            @media screen and (min-width: $desktop-width) {
                font-size: 18px;
            }
        }
        @media screen and (min-width: $tablet-width) {
            font-size: 16px;
        }
        @media screen and (min-width: $desktop-width) {
            font-size: 18px;
        }
        &:before {
            content: "";
            background-image: url(assets/svg/quote.svg);
            position: absolute;
            left: 25px;
            top: 25px;
            width: 39px;
            height: 39px;
            background-repeat: no-repeat;
        }

    }

    ul {
        margin: 20px 0;
        li {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.29;
            color:#3d3d3d;
            padding-left: 33px;
            margin: 4px 0;
            position: relative;
            &:before {
                content:'';
                position: absolute;
                left: 0;
                top: 6px;
                width: 24px;
                height: 1px;
                background-color: #44d4a8;
            }
        }
    }

    ol {
        margin: 20px 0;
        counter-reset: li;
        li {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.29;
            color:#3d3d3d;
            padding-left: 33px;
            margin: 4px 0;
            position: relative;
            &:before {
                counter-increment: li;
                content:"" counter(li) ". ";
                position: absolute;
                left: 15px;
            }
        }
    }


    .wp-caption.alignright, .alignright {
        margin: 30px 0;
        object-fit: contain;
        max-height: 500px;
        width: 100%;
        @media screen and (min-width: $desktop-width) {
            float: right;
            margin: 0 0 30px 30px;
            width: 45%;
        }
    }
    .wp-caption.alignleft, .alignleft {
        margin: 30px 0;
        object-fit: contain;
        max-height: 500px;
        width: 100%;
        @media screen and (min-width: $desktop-width) {
            float: left; 
            margin: 0 30px 30px 0;
            width: 45%;
        }
    }
    .wp-caption-text {
        text-align: center;
        font-style: italic;
        padding-top: 5px;
    }
}